import { QueryClient, useQuery } from "@tanstack/vue-query";
import { ref } from "vue";
import QueryKeys from "@/vue-query/QueryKeys";
import { DashboardDto } from "@/api/contracts/models";
import { useProjectApi } from "../api/useProjectApi";

export function useDashboardsQuery(projectId: Ref<string>) {
  const client = useProjectApi();

  const enabled = computed(() => !!projectId.value);

  return useQuery(
    [QueryKeys.WallDashboards, projectId],
    () => client.listDashboards(projectId.value),
    { enabled: enabled, staleTime: Infinity,  refetchOnWindowFocus: false }
  );
}

export function useDashboardsQueryPrefetch(
  queryClient: QueryClient,
  projectId: Ref<string>
): Promise<void> {
  const client = useProjectApi();
  return queryClient.prefetchQuery<DashboardDto[]>(
    [QueryKeys.WallDashboards, projectId],
    () => client.listDashboards(projectId.value)
  );
}
